// Path: middleware/set-page-header.ts
// Adjusted middleware for Nuxt.js to add a custom header with the page name

export default function ({ res, route }) {
  // Check if the response object and its headers are available
  if (res && res.setHeader) {
    // Use the route name or a custom identifier as the header value
    const pageName = route.name || 'unknown'
    // Set custom header
    res.setHeader('X-Page-Name', pageName)
  }
}
