import { Middleware } from '@nuxt/types'

const addParamsNoIndexTag: Middleware = ({ app, route }) => {
  const query = { ...route.query }
  delete query.page
  const noQueryParams = Object.keys(query).length

  if (noQueryParams > 0) {
    // @ts-ignore
    const meta = app.head?.meta
    const robots = meta.find((tag) => tag.name === 'robots')

    if (robots) {
      if (!robots.content.includes('noindex')) {
        robots.content += ',noindex'
      }
    } else {
      meta.push({
        name: 'robots',
        content: 'noindex',
      })
    }
  }
}

export default addParamsNoIndexTag
