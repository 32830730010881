import { Middleware } from '@nuxt/types'
import { getMetaFromRoute } from '../utility/route'

const gtmPageDataLayer: Middleware = (ctx) => {
  const { route, $gtm } = ctx
  let pageType = ''
  const meta = getMetaFromRoute(route)
  if (meta.type) {
    switch (meta.type) {
      case 'campsite-details':
        pageType = 'product'
        break
      default:
        pageType = meta.type
    }
  } else {
    switch (route.name) {
      // TODO: put a type on the book page
      case 'campsite-slug-book':
        pageType = 'booking'
        break
    }
  }
  if (pageType) {
    $gtm.push({
      page: {
        type: pageType,
      },
    })
  }
}

export default gtmPageDataLayer
