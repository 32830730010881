
import {
  useAuthStore,
  useCurrencyStore,
  useFacetsStore,
  usePinia,
  useSearchWizardStore,
} from '#imports'
import LazyHydrate from 'vue-lazy-hydration'
import { Component, Vue, Watch } from '~/utility/pu-class-decorator'
import ErrorCapture from '~/mixins/ErrorCaptured'
import DefaultTemplate from './default.vue'

@Component({
  components: {
    DefaultTemplate,
    LazyHydrate,
  },
  mixins: [ErrorCapture],
  setup() {
    const authStore = useAuthStore()
    const searchWizardStore = useSearchWizardStore(usePinia())
    searchWizardStore.isSearchWizardOpen = false
    const facetsStore = useFacetsStore()
    // setup to fetch currencies ASAP
    const currencyStore = useCurrencyStore()
    return {
      authStore,
      currencyStore,
      facetsStore,
      searchWizardStore,
    }
  },
})
export default class HomepageLayout extends Vue {
  searchWizardStore: ReturnType<typeof useSearchWizardStore>

  @Watch('searchWizardStore.isSearchWizardOpen')
  searchIsOpen() {
    if (process.client && !this.searchWizardStore.isSearchWizardOpen) {
      document
        .getElementById('__nuxt')
        ?.classList.remove('homepage-search-open', 'active-tab-search')
    }
  }

  mounted() {
    this.$nextTick(() => {
      requestAnimationFrame(() => {
        this.searchIsOpen()
        if (
          document
            .getElementById('__nuxt')
            ?.classList.contains('homepage-search-open')
        ) {
          document.getElementById('autosuggest__input')?.focus()
        }
      })
    })
  }
}
