import { Middleware } from '@nuxt/types'

const endsWithNoindex = (path: string) => path.endsWith('/noindex/')
const startsWithLang = (path: string, lang: string) => path.startsWith(`/${lang}/`)
const hasAmpersandBeforeQuery = (path: string) => {
  const queryIndex = path.indexOf('?')
  return queryIndex === -1 ? path.includes('&') : path.slice(0, queryIndex).includes('&')
}

const languagePrefixes = ['pt', 'de-ch', 'sl', 'ro', 'fr-ch', 'lv', 'de-hr', 'sk', 'lt', 'zh']

const shouldReturn404 = (path: string): boolean =>
  endsWithNoindex(path) ||
  languagePrefixes.some(lang => startsWithLang(path, lang)) ||
  hasAmpersandBeforeQuery(path)

const handle404: Middleware = (context) => {
  if (!context.req) return
  const { req, error } = context
  const path = req.url
  if (shouldReturn404(path)) {
    error({
      statusCode: 404,
      message: `Resource not found: ${path}`,
    })
  }
}

export default handle404
