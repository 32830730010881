import { getUrlPrefix } from '../locales'

// this prefix is all urls, and so put the lang prefix after and the rest of the url
const PREFIX = '/_/fallback'

export function langFallback$Fetch<T>(
  langCode: string,
  url: string,
  fetchOptions?,
) {
  const langPrefix = getUrlPrefix(langCode)
  return $fetchDefault<T>(`${PREFIX}${langPrefix}${url}`, fetchOptions)
}

export function langFallbackFetch(
  langCode: string,
  url: string,
  init?: RequestInit,
) {
  const langPrefix = getUrlPrefix(langCode)
  return fetch(`${PREFIX}${langPrefix}${url}`, init)
}

export function getLangFallbackUrl(langCode: string, url: string) {
  const langPrefix = getUrlPrefix(langCode)
  return `${PREFIX}${langPrefix}${url}`
}

export function getDomainName() {
  return window.location.hostname
}

// note only need this if POST and data is used
async function $fetchDefault<T>(url: string, init: any = {}) {
  if (init?.method === 'POST' && 'data' in init) {
    init.body = init.data
    delete init.data
  }
  return await $fetch<T>(url, init)
}
