import { Middleware } from '@nuxt/types'
import { isDayPast } from '~/utility/date/relative'
import { isValidDate } from '~~/utility/date/isValidDate'

const dropIncorrectDates: Middleware = ({ redirect, route }) => {
  const { path, query, hash } = route
  if (query.arrive) {
    const arrive = new Date(query.arrive as string)
    const depart = new Date(query.depart as string)
    if (
      !isValidDate(query.arrive) ||
      !isValidDate(query.depart) ||
      isDayPast(arrive) ||
      depart < arrive
    ) {
      const redirectQuery = {
        ...query,
        arrive: undefined,
        depart: undefined,
      }
      redirect(301, { path, query: redirectQuery, hash })
    }
  }
}

export default dropIncorrectDates
