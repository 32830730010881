import { Middleware } from '@nuxt/types'

const trailingSlash: Middleware = ({ redirect, route }) => {
  if (!route.path.endsWith('/')) {
    const { path, query, hash } = route
    const nextPath = `${path}/`
    const nextRoute = { path: nextPath, query, hash }
    redirect(301, nextRoute)
  }
}

export default trailingSlash
