import { LOCALES_BY_CODE } from '~/lang/locales'

function returnUrlParts(currentPath: string, langCode: string) {
  const [path, query] = currentPath.split('?')
  const url = path.split('/').filter((str: string) => str !== '')
  const langInfo = LOCALES_BY_CODE[langCode]
  const hasLangInUrl = Object.keys(LOCALES_BY_CODE).includes(url[0])

  if (hasLangInUrl) {
    url[0] = langInfo.url
  } else {
    url.unshift(langCode)
  }

  if (!langInfo.url) url.splice(0, 1)

  const resultUrl: string = url.length ? `${url.join('/')}/` : ''
  const resultQuery = query ? `?${query}` : ''

  return { resultUrl, query, resultQuery }
}
export function languageRedirect(currentPath: string, langCode: string) {
  const { query, resultQuery, resultUrl } = returnUrlParts(
    currentPath,
    langCode,
  )
  if (query) {
    window.location.replace(`/${resultUrl}${resultQuery}`)
  } else {
    window.location.replace(`/${resultUrl}`)
  }
}

export function getLanguageUrl(currentPath: string, langCode: string) {
  const { query, resultQuery, resultUrl } = returnUrlParts(
    currentPath,
    langCode,
  )
  if (query) {
    return `/${resultUrl}${resultQuery}`
  } else {
    return `/${resultUrl}`
  }
}
