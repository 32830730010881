import { defineNuxtRouteMiddleware } from '#app'

// there is some type issue here, context is typed as route
export default defineNuxtRouteMiddleware((context) => {
  if (process.server) {
    const newrelic = require('newrelic')
    // @ts-ignore
    newrelic.setTransactionName(context.route.name)
    if (process.env.NODE_ENV !== 'production') {
      // @ts-ignore
      console.log('newRelicName', context.route.name)
    }
  }
})
