export function puNumber(
  value: number,
  locale: string,
  options?: string,
): string {
  const o = options || ''
  if (typeof value !== 'number') return ''
  const config = {
    style: getStyleFromOptions(o),
  }
  const minimumFractionDigits = getMinFractionDigitsFromOptions(o)
  if (minimumFractionDigits) {
    ;(config as any)['minimumFractionDigits'] = minimumFractionDigits
  }
  const maximumFractionDigits = getMaxFractionDigitsFromOptions(o)
  if (maximumFractionDigits) {
    ;(config as any)['maximumFractionDigits'] = maximumFractionDigits
  }
  return new Intl.NumberFormat(locale, config).format(value)

  function getFromOptions(options: string, optionToFind: string): string {
    const option = options.split(' ').find((o) => o.startsWith(optionToFind))
    return option ? option.split(':')[1] : ''
  }

  function getStyleFromOptions(options: string): string {
    const style = getFromOptions(options, 'style')
    return style ? style : 'decimal'
  }

  function getMinFractionDigitsFromOptions(options: string): number {
    const minimumFractionDigits = getFromOptions(
      options,
      'minimumFractionDigits',
    )
    return minimumFractionDigits ? parseInt(minimumFractionDigits) : 0
  }

  function getMaxFractionDigitsFromOptions(options: string) {
    const maximumFractionDigits = getFromOptions(
      options,
      'maximumFractionDigits',
    )
    return maximumFractionDigits ? parseInt(maximumFractionDigits) : undefined
  }
}
