
import {
  useAuthStore,
  useCurrencyStore,
  useFacetsStore,
  usePinia,
  useSearchWizardStore,
} from '#imports'
import { Component, Vue } from '~/utility/pu-class-decorator'
import ErrorCapture from '~/mixins/ErrorCaptured'

@Component({
  layout: 'user-account',
  mixins: [ErrorCapture],
  setup() {
    const searchWizardStore = useSearchWizardStore(usePinia())
    // setup facet store so facets available to all components
    const facetsStore = useFacetsStore()
    // setup to fetch currencies ASAP
    const currencyStore = useCurrencyStore()
    const authStore = useAuthStore()
    return {
      authStore,
      currencyStore,
      facetsStore,
      searchWizardStore,
    }
  },
})
export default class UserAccountLayout extends Vue {}
