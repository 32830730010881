import { LOCALES_BY_CODE } from '~/lang/locales'

const LANG_STORAGE_KEY = 'pitchup-language-v2'

export default function language({ app, route }) {
  // the replace " is to handle lang codes from pitchupcom
  const currentLanguageUser = localStorage
    .getItem(LANG_STORAGE_KEY)
    ?.replaceAll('"', '')
  const didUserCancelLangChange = currentLanguageUser === 'user-cancelled'
  if (didUserCancelLangChange) {
    return
  }
  if (currentLanguageUser && currentLanguageUser !== app.i18n.locale) {
    const url = route.fullPath.split('/')
    const langInfo = LOCALES_BY_CODE[currentLanguageUser]
    if (!langInfo) {
      localStorage.removeItem(LANG_STORAGE_KEY)
      return
    }
    if (url.shift() === '') {
      if (Object.keys(LOCALES_BY_CODE).includes(url[0])) url.shift()
      if (langInfo.url) url.unshift(langInfo.url)
      window.location.replace(`/${url.join('/')}`)
    }
  }
}
