import { render, staticRenderFns } from "./no-navbar.vue?vue&type=template&id=2fc80e3d&scoped=true"
import script from "./no-navbar.vue?vue&type=script&lang=ts"
export * from "./no-navbar.vue?vue&type=script&lang=ts"
import style0 from "./no-navbar.vue?vue&type=style&index=0&id=2fc80e3d&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2fc80e3d",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PuHomepageLink: require('/app/apps/pu-links/link-homepage/PuHomepageLink.vue').default,Translate: require('/app/components/Translate.vue').default,FeefoBadge: require('/app/components/layouts/components/FeefoBadge.vue').default,FooterLinks: require('/app/components/layouts/components/FooterLinks.vue').default,Version: require('/app/components/layouts/components/Version.vue').default,Copyright: require('/app/components/layouts/components/Copyright.vue').default})
