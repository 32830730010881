import { Middleware } from '@nuxt/types'
import { getMetaFromRoute } from '~/utility/route'

const mapsUrlRedirect: Middleware = ({ redirect, route, $isDesktop }) => {
  if ($isDesktop) {
    const meta = getMetaFromRoute(route)
    if (meta.type && meta.type === 'maps') {
      const url = route.fullPath.replace('/maps/', '/search/')
      redirect(302, url)
    }
  }
}

export default mapsUrlRedirect
