
import { Component, Vue } from '~/utility/pu-class-decorator'
import ErrorCapture from '~/mixins/ErrorCaptured'
import DefaultTemplate from './default.vue'

@Component({
  components: {
    DefaultTemplate,
  },
  mixins: [ErrorCapture],
})
export default class DefaultNoFooter extends Vue {}
