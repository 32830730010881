import { Middleware } from '@nuxt/types'
import { sanitize } from '../utility/sanitize'

const versionHeader: Middleware = ({ $config, res }) => {
  if (process.client) {
    return
  }
  res.setHeader('X-Alfred-Version', $config.public.alfredVersion)
  const currentXkey = res.getHeader('xkey')
  const newXkey = sanitize('alfredVersion:' + $config.public.alfredVersion)
  if (currentXkey === undefined) {
    res.setHeader('xkey', newXkey)
  } else {
    res.setHeader('xkey', `${currentXkey.toString()} ${newXkey}`)
  }
}

export default versionHeader
