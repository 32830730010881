import { LocaleObject } from 'vue-i18n'

declare module 'vue-i18n' {
  interface LocaleObject {
    name: string
    englishName?: string
    code: string
    url: string
    iso: string
    fileName: string
    currency: string
    flag: string
    feefoBadge: string
    distanceUnit: string
    mapCenter: {
      lat: number
      lng: number
    }
    alternateHreflang?: string
    paypal: string
  }
}

interface LocalesObject {
  [key: string]: LocaleObject
}

export const LOCALES: LocaleObject[] = [
  {
    name: 'British English',
    code: 'en-gb',
    url: '',
    iso: 'en-GB',
    fileName: 'en',
    currency: 'GBP',
    flag: 'GB',
    feefoBadge: feefoBadgeLink('en-gb'),
    distanceUnit: 'mi',
    mapCenter: {
      lat: 53.396432,
      lng: -1.669922,
    },
    paypal: 'en_GB',
  },
  {
    name: 'American English',
    code: 'en-us',
    url: 'en-us',
    iso: 'en-US',
    fileName: 'en-US',
    currency: 'USD',
    flag: 'US',
    feefoBadge: feefoBadgeLink('en-us'),
    distanceUnit: 'mi',
    mapCenter: {
      lat: 45.6795472,
      lng: -112.4616737,
    },
    paypal: 'en_US',
  },
  {
    name: 'Australian English',
    code: 'en-au',
    url: 'en-au',
    iso: 'en-AU',
    fileName: 'en-AU',
    currency: 'AUD',
    flag: 'AU',
    feefoBadge: feefoBadgeLink('en-au'),
    distanceUnit: 'km',
    mapCenter: {
      lat: -25.73288704,
      lng: 134.4910001,
    },
    paypal: 'en_AU',
  },
  {
    name: 'Canadian English',
    code: 'en-ca',
    url: 'en-ca',
    iso: 'en-CA',
    fileName: 'en-CA',
    currency: 'CAD',
    flag: 'CA',
    feefoBadge: feefoBadgeLink('en-ca'),
    distanceUnit: 'km',
    mapCenter: {
      lat: 61.36206324,
      lng: -98.30777028,
    },
    paypal: 'en_US',
  },
  {
    name: 'New Zealand English',
    code: 'en-nz',
    url: 'en-nz',
    iso: 'en-NZ',
    fileName: 'en-NZ',
    currency: 'NZD',
    flag: 'NZ',
    feefoBadge: feefoBadgeLink('en-nz'),
    distanceUnit: 'km',
    mapCenter: {
      lat: -40.9006,
      lng: 174.886,
    },
    paypal: 'en_US',
  },
  {
    name: 'South African English',
    code: 'en-za',
    url: 'en-za',
    iso: 'en-ZA',
    fileName: 'en-ZA',
    currency: 'ZAR',
    flag: 'ZA',
    feefoBadge: feefoBadgeLink('en-za'),
    distanceUnit: 'km',
    mapCenter: {
      lat: -29.00034095,
      lng: 25.08390093,
    },
    paypal: 'en_US',
  },
  {
    name: 'Irish English',
    code: 'en-ie',
    url: 'en-ie',
    iso: 'en-IE',
    fileName: 'en-IE',
    currency: 'EUR',
    flag: 'IE',
    feefoBadge: feefoBadgeLink('en-ie'),
    distanceUnit: 'km',
    mapCenter: {
      lat: 53.1754487,
      lng: -8.137935687,
    },
    paypal: 'en_US',
  },
  // French flavours
  {
    name: 'Français',
    englishName: 'French',
    code: 'fr',
    url: 'fr',
    iso: 'fr-FR',
    fileName: 'fr-FR',
    currency: 'EUR',
    flag: 'FR',
    feefoBadge: feefoBadgeLink('fr-fr'),
    distanceUnit: 'km',
    mapCenter: {
      lat: 46.63,
      lng: 2.46,
    },
    paypal: 'fr_FR',
  },
  {
    name: 'Français canadien',
    englishName: 'Canadian French',
    code: 'fr-ca',
    url: 'fr-ca',
    iso: 'fr-CA',
    fileName: 'fr-CA',
    currency: 'CAD',
    flag: 'CA',
    feefoBadge: feefoBadgeLink('fr-ca'),
    distanceUnit: 'km',
    mapCenter: {
      lat: 40.24448698,
      lng: -3.647550473,
    },
    paypal: 'fr_CA',
  },
  {
    name: 'Français de Belgique',
    englishName: 'Belgian French',
    code: 'fr-be',
    url: 'fr-be',
    iso: 'fr-BE',
    fileName: 'fr-BE',
    currency: 'EUR',
    flag: 'BE',
    feefoBadge: feefoBadgeLink('fr-be'),
    distanceUnit: 'km',
    mapCenter: {
      lat: 50.63981576,
      lng: 4.640651139,
    },
    paypal: 'fr_FR',
  },
  // Portuguese flavours, without Portugal though
  {
    name: 'Português Brasileiro',
    englishName: 'Brazilian Portuguese',
    code: 'pt-br',
    url: 'pt-br',
    iso: 'pt-BR',
    fileName: 'pt-BR',
    // See https://gitlab.pitchup.com/pitchup/alfred/issues/209#note_328112
    // for the reason to use EUR for Brazil
    currency: 'EUR',
    flag: 'BR',
    feefoBadge: feefoBadgeLink('pt-br'),
    distanceUnit: 'km',
    mapCenter: {
      lat: 39.59550671,
      lng: -8.501043613,
    },
    // alternateHreflang: 'pt',
    paypal: 'pt_BR',
  },
  // Portuguese
  {
    name: 'Português',
    englishName: 'Portuguese',
    code: 'pt-pt',
    url: 'pt-pt',
    iso: 'pt-PT',
    fileName: 'pt-PT',
    currency: 'EUR',
    flag: 'PT',
    feefoBadge: feefoBadgeLink('pt-pt'),
    distanceUnit: 'km',
    mapCenter: {
      lat: 39.59550671,
      lng: -8.501043613,
    },
    alternateHreflang: 'pt',
    paypal: 'pt_PT',
  },
  // Chinese is a macro-language, the top language without locale doesn't exist
  {
    name: '简体中文',
    englishName: 'Simplified Chinese',
    code: 'zh-hans',
    url: 'zh-hans',
    iso: 'zh-hans',
    fileName: 'zh-CN',
    currency: 'CNY',
    flag: 'CN',
    feefoBadge: feefoBadgeLink('zh-hans'),
    distanceUnit: 'km',
    mapCenter: {
      lat: 22.22311688,
      lng: 113.5093212,
    },
    alternateHreflang: 'zh',
    paypal: 'zh_CN',
  },
  // Others without country locales
  {
    name: 'Nederlands',
    englishName: 'Dutch',
    code: 'nl',
    url: 'nl',
    iso: 'nl',
    fileName: 'nl-NL',
    currency: 'EUR',
    flag: 'NL',
    feefoBadge: feefoBadgeLink('nl-nl'),
    distanceUnit: 'km',
    mapCenter: {
      lat: 52.1007899,
      lng: 5.28144793,
    },
    paypal: 'nl_NL',
  },
  {
    name: 'Polski',
    englishName: 'Polish',
    code: 'pl',
    url: 'pl',
    iso: 'pl',
    fileName: 'pl-PL',
    currency: 'PLN',
    flag: 'PL',
    feefoBadge: feefoBadgeLink('pl'),
    distanceUnit: 'km',
    mapCenter: {
      lat: 52.12759564,
      lng: 19.39012835,
    },
    paypal: 'pl_PL',
  },
  {
    name: 'Català',
    englishName: 'Catalan',
    code: 'ca',
    url: 'ca',
    iso: 'ca',
    fileName: 'ca-ES',
    currency: 'EUR',
    flag: 'CATALONIA',
    feefoBadge: feefoBadgeLink('ca-es'),
    distanceUnit: 'km',
    mapCenter: {
      lat: 40.24448698,
      lng: -3.647550473,
    },
    paypal: 'es_ES',
  },
  {
    name: 'Česky',
    englishName: 'Czech',
    code: 'cs',
    url: 'cs',
    iso: 'cs',
    fileName: 'cs-CZ',
    currency: 'CZK',
    flag: 'CZ',
    feefoBadge: feefoBadgeLink('cs'),
    distanceUnit: 'km',
    mapCenter: {
      lat: 49.73341233,
      lng: 15.31240163,
    },
    paypal: 'cs_CZ',
  },
  {
    name: 'Dansk',
    englishName: 'Danish',
    code: 'da',
    url: 'da',
    iso: 'da',
    fileName: 'da-DK',
    currency: 'DKK',
    flag: 'DA',
    feefoBadge: feefoBadgeLink('da'),
    distanceUnit: 'km',
    mapCenter: {
      lat: 62.05385403,
      lng: -6.880954234,
    },
    paypal: 'da_DK',
  },
  {
    name: 'Deutsch',
    englishName: 'German',
    code: 'de',
    url: 'de',
    iso: 'de',
    fileName: 'de-DE',
    currency: 'EUR',
    flag: 'DE',
    feefoBadge: feefoBadgeLink('de'),
    distanceUnit: 'km',
    mapCenter: {
      lat: 51.10698181,
      lng: 10.38578051,
    },
    paypal: 'de_DE',
  },
  {
    name: 'Español',
    englishName: 'Spanish',
    code: 'es',
    url: 'es',
    iso: 'es',
    fileName: 'es-ES',
    currency: 'EUR',
    flag: 'ES',
    feefoBadge: feefoBadgeLink('es'),
    distanceUnit: 'km',
    mapCenter: {
      lat: 40.24448698,
      lng: -3.647550473,
    },
    paypal: 'es_ES',
  },
  {
    name: 'Ελληνικά',
    englishName: 'Greek',
    code: 'el',
    url: 'el',
    iso: 'el',
    fileName: 'el-GR',
    currency: 'EUR',
    flag: 'GR',
    feefoBadge: feefoBadgeLink('el'),
    distanceUnit: 'km',
    mapCenter: {
      lat: 39.07469623,
      lng: 22.95555794,
    },
    paypal: 'el_GR',
  },
  {
    name: 'Magyar',
    englishName: 'Hungarian',
    code: 'hu',
    url: 'hu',
    iso: 'hu',
    fileName: 'hu-HU',
    currency: 'HUF',
    flag: 'HU',
    feefoBadge: feefoBadgeLink('hu'),
    distanceUnit: 'km',
    mapCenter: {
      lat: 47.16277506,
      lng: 19.39559116,
    },
    paypal: 'hu_HU',
  },
  {
    name: 'Italiano',
    englishName: 'Italian',
    code: 'it',
    url: 'it',
    iso: 'it',
    fileName: 'it-IT',
    currency: 'EUR',
    flag: 'IT',
    feefoBadge: feefoBadgeLink('it'),
    distanceUnit: 'km',
    mapCenter: {
      lat: 42.79662641,
      lng: 12.07001339,
    },
    paypal: 'it_IT',
  },
  {
    name: 'Norsk',
    englishName: 'Norwegian',
    code: 'no',
    url: 'no',
    iso: 'no',
    fileName: 'no-NO',
    currency: 'NOK',
    flag: 'NO',
    feefoBadge: feefoBadgeLink('no'),
    distanceUnit: 'km',
    mapCenter: {
      lat: 68.75015572,
      lng: 15.34834656,
    },
    paypal: 'no_NO',
  },
  {
    name: 'Русский',
    englishName: 'Russian',
    code: 'ru',
    url: 'ru',
    iso: 'ru',
    fileName: 'ru-RU',
    currency: 'RUB',
    flag: 'RU',
    feefoBadge: feefoBadgeLink('ru'),
    distanceUnit: 'km',
    mapCenter: {
      lat: 61.98052209,
      lng: 96.68656112,
    },
    paypal: 'ru_RU',
  },
  {
    name: 'Svenska',
    englishName: 'Swedish',
    code: 'sv',
    url: 'sv',
    iso: 'sv',
    fileName: 'sv-SE',
    currency: 'SEK',
    flag: 'SE',
    feefoBadge: feefoBadgeLink('sv'),
    distanceUnit: 'km',
    mapCenter: {
      lat: 13.73943744,
      lng: -88.87164469,
    },
    paypal: 'sv_SE',
  },
]

export const LOCALES_BY_CODE: LocalesObject = LOCALES.reduce(
  (aggregate, locale) => {
    aggregate[locale.code] = locale
    return aggregate
  },
  {} as LocalesObject,
)

export const LOCALE_CODES: string[] = Object.keys(LOCALES_BY_CODE)

export const I18N = {
  strategy: 'no_prefix',
  sortRoutes: false,
  parsePages: false,
  locales: LOCALES,
  defaultLocale: 'en-gb',
  seo: false,
  detectBrowserLanguage: false,
  vueI18n: {
    fallbackLocale: {
      default: ['en-gb'],
      'en-gb': getFallbackLocaleFor('en-gb'),
      'en-us': getFallbackLocaleFor('en-us'),
      'en-ca': ['en-us'],
      'en-ie': ['en-gb'],
      'en-au': ['en-nz'],
      'en-nz': ['en-au'],
      'en-za': getFallbackLocaleFor('en-za'),
      fr: getFallbackLocaleFor('fr'),
      'fr-be': getFallbackLocaleFor('fr-be'),
      'fr-ca': getFallbackLocaleFor('fr-ca'),
    },
    silentFallbackWarn: true,
    silentTranslationWarn: true,
    formatFallbackMessages: true,
  },
}

export const DISABLED_URL_LOCALE_CODES = ['en', 'en-gb']

export function getFallbackLocaleFor(lang: string): string[] {
  const language = lang.split('-')[0]
  const fallbackLocales = LOCALES.filter(
    (l) => l.code.startsWith(language) && l.code !== lang,
  )
  return fallbackLocales.map((l) => l.code)
}

function feefoBadgeLink(code: string) {
  return `https://api.feefo.com/api/logo?merchantidentifier=pitchup-com&template=Service-Stars-Grey-316x80.png&since=all&accept-language=${code}`
}

export function getLocaleInfo(locale: string) {
  locale = locale ? locale : 'en-gb'
  return LOCALES_BY_CODE[locale]
}

export function getUrlLocaleCode(langCode: string): string {
  langCode = langCode ? langCode : 'en-gb'
  return DISABLED_URL_LOCALE_CODES.map((s) => s.toLocaleLowerCase()).includes(
    langCode.toLowerCase(),
  )
    ? ''
    : langCode
}

// filters the langCode if it needs to be in the url or not
export function getUrlPrefix(langCode?: string): string {
  langCode = langCode ? langCode : 'en-gb'
  const prefix = getUrlLocaleCode(langCode)
  return prefix ? `/${prefix}` : ''
}
