import { Context } from '@nuxt/types'
import Vue from 'vue'
import { getCookie } from '~/utility/cookies'
import { MessageFormatFormatter } from '../formatter'

declare module 'vue-i18n' {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface IVueI18n {
    current: {
      currency: string
    }
  }
}

export default async ({ app, route }: Context) => {
  const i18n = app.i18n

  if (!i18n) return
  function setLocaleInfo(localeCode: string) {
    i18n.localeInfo = i18n.locales.find((l) => l.code === localeCode)!
  }

  if (route.params.lang) {
    setLocaleInfo(route.params.lang)
    await i18n.setLocale(route.params.lang)
  }
  setLocaleInfo(i18n.locale)

  i18n.current = Vue.observable({
    currency: process.server
      ? i18n.localeInfo.currency
      : getCookie('pu-currency') || i18n.localeInfo.currency,
  })
  // @ts-ignore
  i18n.formatter = await loadMessageFormatFormatter(i18n.localeInfo.fileName)

  // beforeLanguageSwitch called right before setting a new locale
  i18n.onBeforeLanguageSwitch = (_oldLocale: string, newLocale: string) => {
    i18n.locale = newLocale
    setLocaleInfo(newLocale)
    i18n.current.currency = process.server
      ? i18n.localeInfo.currency
      : getCookie('pu-currency') || i18n.localeInfo.currency
    setLanguageLocalStorageKey()
  }

  function setLanguageLocalStorageKey() {
    // tslint:disable-next-line strict-type-predicates
    if (typeof localStorage !== 'undefined') {
      localStorage.setItem('pitchup-language-v2', i18n.locale)
    }
  }

  async function loadMessageFormatFormatter(fileName: string) {
    const messages = await import(
      /* webpackChunkName: "lang-[request]", webpackMode: "lazy" */ `../compiled/${fileName}.js`
    )
    return new MessageFormatFormatter(messages.default)
  }
}
