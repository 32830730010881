import { Middleware } from '@nuxt/types'

const dropIncorrectQuoteProperties: Middleware = ({ redirect, route }) => {
  const { path, query, hash } = route
  if (query.adults && !isAdultsValid(query.adults)) {
    const redirectQuery = {
      ...query,
      adults: undefined,
    }
    redirect(301, { path, query: redirectQuery, hash })
  }
}

const isAdultsValid = (adults: unknown): boolean => {
  const parsedAdults = Number(adults)
  return Number.isInteger(parsedAdults)
}

export default dropIncorrectQuoteProperties
