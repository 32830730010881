import { Middleware } from '@nuxt/types'
import { safeJSONParse } from '../utility/json'

const updateBoundsParams: Middleware = ({ redirect, route }) => {
  if (process.server) {
    const { path, query, hash } = route

    if (query.bounds) {
      const bounds = safeJSONParse(query.bounds as string)
      if (!bounds) return
      // @ts-ignore
      const { north, south, east, west } = bounds

      if (north || south || east || west) {
        if (north && south && east && west) {
          const northEast = {
            lat: north,
            lng: east,
          }

          const southWest = {
            lat: south,
            lng: west,
          }
          const redirectQuery = {
            ...query,
            bounds: JSON.stringify({
              northEast,
              southWest,
            }),
          }

          redirect(301, { path, query: redirectQuery, hash })
        } else {
          const redirectQuery = {
            ...query,
          }
          delete redirectQuery.bounds
          redirect(301, { path, query: redirectQuery, hash })
        }
      }
    }
  }
}

export default updateBoundsParams
