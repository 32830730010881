export class MessageFormatFormatter {
  // only log missing once
  private missing = {}

  constructor(private messages: Record<string, (d: any) => string[]>) {}

  interpolate(_message: any, values: Object, path: string) {
    if (!this.messages[path]) {
      if (!this.missing[path]) {
        // console.warn('missing translation for:', path)
        this.missing[path] = true
      }
      return path
    }
    return this.messages[path](values).flat()
  }
}
